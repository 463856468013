import React, { useEffect, useState,Suspense} from "react";
import { db } from '../lib/firebase.prod';
import Header from '../containers/Header';
import Footer from '../components/Footer';
import {analytics} from "../lib/firebase.prod.js";

import './home.scss';
import './../components/backdrop.scss';

const CardFlip = React.lazy(() =>
new Promise((resolve, reject) =>
  setTimeout(() => resolve(import('../components/CardFlip')), 100))
);
const Backdrop = React.lazy(() =>
new Promise((resolve, reject) =>
  setTimeout(() => resolve(import('../components/Backdrop')), 1000)),
  {
    fallback: <div>Loading...</div>
  }
);

const Home = () => {

  const baseUrl = 'http://res.cloudinary.com/dxaefaibx/image/fetch/g_auto/f_auto/';

  const [filmsList, setFilmsList] = useState([]);

  const getFilms = async () => {
    try {
      const list = [];
      const snapshot = await db.collection('films').get();
      snapshot.forEach((doc) => {
        list.push(doc.data());
      });
      
      setFilmsList(list);
      
    } catch (e)
     {
      console.log(
        "Seems like there are no more films. We got to upload some more."
      ,e);
    }
  };
    
  //componentDidMount
  useEffect(() => {
    async function fetchData() {
    await getFilms();
  }
  fetchData();
  }, []);
  
  const random = Math.floor(Math.random() * 85);

  const [activeCard, setActiveCard] = useState(random);
  
  //Randomly Generates a number based on the total amount of films
  const randomCardNumber = () => {
    const len = filmsList.length;
    const randomCard = Math.floor((Math.random() * (len)));
    setActiveCard(randomCard);


    //setFilmsList(getFilms(randomCard));
    //setFilmsList to be 1 less each time random card is called


    //each random number called push to a new array (state)
    //in random number function if random function exist then run back randomCardNumber
    //when the number run out problem
  };

  useEffect(() => {
    analytics.logEvent('landing_page_view');
  });

  if(filmsList.length < 1){
    return <div><div className="overlay"></div><div className="containEverything"><Header/><Footer/></div></div>
  }
  return (
    <>
    <Suspense fallback={<div className="backgroundBlur"></div>}>
      <Backdrop key={filmsList.id} randomCardNumber={randomCardNumber} activeCard={activeCard} baseUrl={baseUrl} filmsList={filmsList}/>
    </Suspense>
    
    <div className="overlay"></div>

    <div className="containEverything">
      
      <Header/>

      <Suspense fallback={<div className="backgroundBlur"></div>}>
      <div className="card">
          <CardFlip key={filmsList.id} randomCardNumber={randomCardNumber} activeCard={activeCard} baseUrl={baseUrl} filmsList={filmsList}/> 
      </div>
      </Suspense>   

      <div className="containerBtn">
        <button onClick={randomCardNumber} className="homebtn homebtn-1"> 
          <svg><rect x="0" y="0" fill="none" width="100%" height="100%"/></svg>
          GENERATE MOVIE
        </button>
      </div>

      <Footer/>

    </div>

    </>
  );
}

export default Home;
