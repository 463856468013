import React from 'react';
import {Link} from 'react-router-dom';
// import React, {useState} from 'react';
// import Menu from '../containers/Menu';

import './header.scss';

function Header(){

    // const [showMenu, setShowMenu] = useState(false);

    // const toggleMenu = () => {
    //     setShowMenu(!showMenu);
    // }

    return(
        <>
        <div className="headerAll">
            <div className="headerAll__headerTitle">
                <Link  to="/">
                {/* <Link  onClick={() => window.location.reload(false)}> */}
                    {/* <h1>modnar</h1> */}
                    <img src={process.env.PUBLIC_URL + '/modnar-white-logo.svg'} alt="Modnar Logo"/>

                </Link>
            </div>

            {/* <div className="headerAll__headerMenu">
                <button
                id="openMenu"
                type="button"
                onClick={toggleMenu}
                >MENU</button>

                {showMenu && 
                <Menu handleClose={toggleMenu}/>
                }  

            </div> */}
        </div>
        </>
    );
}

export default Header;