import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import * as ROUTES from './constants/Routes';

function App() {
  return (
    <Router>

      <Switch>

        <Route exact path={ROUTES.Home}>
          <Home />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
        
      </Switch>

    </Router>
  );
}
export default App;