import Firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/analytics";

//1) only use once
// import { seedDatabase } from '../seed';

const config = {
    apiKey: "AIzaSyAMhNbhsbgDfrWcJb8XrgkS3w2trZTqcVA",
    authDomain: "themodnar-19r51n.firebaseapp.com",
    projectId: "themodnar-19r51n",
    storageBucket: "themodnar-19r51n.appspot.com",
    messagingSenderId: "792924623763",
    appId: "1:792924623763:web:9c41ff48481f2318face66",
    measurementId: "G-2SWCG3CK2F"
};

const firebase = Firebase.initializeApp(config);

const analytics = firebase.analytics();

// 2) when seeding the database you'll have to uncomment this!
// seedDatabase(firebase);

// 3) once you have populated the database (only run once!), re-comment this so you don't get duplicate data
export const db = firebase.firestore();

export {firebase, analytics};