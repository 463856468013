import React from "react";

import './footer.scss';

function Footer(){
 
    return(
        <div className="footerWrapper">
            <div className="footerWrapper__copyright">© 2021 The Modnar. All Rights Reserved.</div>
            <div className="footerWrapper__madeBy">Built and Designed by <span id={"designedBy"} onClick={() => {
        window.open("https://dribbble.com/keemabritish", "_blank");
      }}>Akeema British</span></div>
        </div>
    );
}

export default Footer;
