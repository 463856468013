import React from "react";
import {Link} from 'react-router-dom';

import Header from '../containers/Header';
import Footer from '../components/Footer';

import './notfound.scss';

const NotFound = () => {
    return (
        <>
        
        <div className="NotFound">
            <img src={process.env.PUBLIC_URL + '/images/birdsofprey.jpeg'} alt='Birds of Prey' style={{position: 'absolute',width: '100vw', zIndex: '-2'}}/>
        </div>

        <div className="headerTitle__Header">
            <Header />
        </div>
        <Footer />
        <div className="NFtext">
            <div className="NFtext__text">
                <h1>It's all good</h1>
                <p>Don't be embarassed! Human Error is inevitable.</p>
            </div>
            <div className="NFtext__button">
                <Link to="/" style={{color: 'white'}} className="btn btn-1">
                    <svg >
                        <rect x="0" y="0" fill="none" width="42%" height="100%"/>
                    </svg>
                    <h4>Back Home</h4>
                </Link>
            </div>
        </div>

        </>
    );
}

export default NotFound;